import { Controller } from "@hotwired/stimulus"
import { initSelect2 } from 'shared/select2_init'; 
// Connects to data-controller="back--statistics--v2--statistics-abstract-filter"
export default class extends Controller {
  get autoRefresh() {
    return false
  }

  connect() {
    this.element['controller'] = this
    this.startDate = $('.filter_card').data().datesOne.split('-')[0]
    this.endDate = $('.filter_card').data().datesOne.split('-')[1]
    this.startDateTwo = $('.filter_card').data().datesTwo.split('-')[0]
    this.endDateTwo = $('.filter_card').data().datesTwo.split('-')[1]
    let start_last_year;
    let end_last_year;
    $(this.element).find('#statistics-v2-filters__shop').on('select2:select', (e) => {
      if($('#statistics-v2-filters__shop').val() == 'all' && $('#statistics-v2-display__separate-shops__container').data().canDisplay) {
        $('#statistics-v2-display__separate-shops__container').addClass('active')
      } else {
        $('#statistics-v2-display__separate-shops__container').removeClass('active')
        $('#statistics-v2-display__separate-shops').prop('checked', false)
      }
    })

    $(this.element).find('#statistics-v2-filters__source-order-types').on("select2:unselecting", (e) => {
      if($('#statistics-v2-filters__source-order-types').val().length == 1) {
        e.preventDefault()
      }
    })    
    let daterangepickerParams = {
      parentEl: ".filter_card",
      maxDate: moment().add(60, 'days'),
      maxYear: 2030,
      minYear: 2000,
      changeYear: true,
      changeMonth: true,
      showDropdowns: true,
      linkedCalendars: false,
      ranges: {
        "Aujourd'hui": [moment(), moment()],
        'Hier': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Les 7 derniers jours': [moment().subtract(6, 'days'), moment()],
        'Les 30 derniers jours': [moment().subtract(29, 'days'), moment()],
        'Le mois précédent': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
        "L'année en cours": [moment().startOf('year'), moment()],
        "L'année précédente": [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')],
        'Les 30 prochains jours': [moment(), moment().add(30, 'days')]
      },
      startDate: moment(this.startDate.split('/').reverse().join('-')),
      endDate: moment(this.endDate.split('/').reverse().join('-'))
    }

    $('#statistics-v2-filters__rangedatepicker-1').daterangepicker(daterangepickerParams, (start, end, picker) => {
      if(picker == "Personnalisé") {
        $('#statistics-v2-filters__rangedatepicker-1 span').text(start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY'))
      } else {
        $('#statistics-v2-filters__rangedatepicker-1 span').text(picker)
      }
      $('.filter_card').data().datesOne = `${start.format('DD/MM/YYYY')}-${end.format('DD/MM/YYYY')}`
      $('.filter_card').data().dates = `${start.format('DD/MM/YYYY')}-${end.format('DD/MM/YYYY')}`
      this.startDate = start.format('DD/MM/YYYY')
      this.endDate = end.format('DD/MM/YYYY')
      if($('#statistics-v2-filters__rangedatepicker-2').length) {
        start_last_year = start.subtract(1, 'year')
        end_last_year = end.subtract(1, 'year')
        this.startDateTwo = start_last_year.format(('DD/MM/YYYY'))
        this.endDateTwo = end_last_year.format(('DD/MM/YYYY'))
        $('#statistics-v2-filters__rangedatepicker-2 span').text(start_last_year.format('MMMM D, YYYY') + ' - ' + end_last_year.format('MMMM D, YYYY'))
      }
    });  

    daterangepickerParams.startDate = moment(this.startDateTwo.split('/').reverse().join('-'))
    daterangepickerParams.endDate = moment(this.endDateTwo.split('/').reverse().join('-'))
    $('#statistics-v2-filters__rangedatepicker-2').daterangepicker(daterangepickerParams, (start, end, picker) => {
      if(picker == "Personnalisé") {
        $('#statistics-v2-filters__rangedatepicker-2 span').text(start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY'))
      } else {
        $('#statistics-v2-filters__rangedatepicker-2 span').text(picker)
      }
      $('.filter_card').data().datesTwo = `${start.format('DD/MM/YYYY')}-${end.format('DD/MM/YYYY')}`
      this.startDateTwo = start.format('DD/MM/YYYY')
      this.endDateTwo = end.format('DD/MM/YYYY')
    });  


    if(this.autoRefresh) {
      this.filter()
    } else {
      this.setUrls(this.prepareParams())
    }
  }

  prepareParams() {
    let params = {}
    if(this.startDate) {
      params.startDate = this.startDate.split('/').join('-')
      params.endDate = this.endDate.split('/').join('-')
      params.startDateTwo = this.startDateTwo.split('/').join('-')
      params.endDateTwo = this.endDateTwo.split('/').join('-')
    }

    if($('#statistics-v2-filters__shop').length && $('#statistics-v2-filters__shop').val() != 'all') {
      params.shopId = $('#statistics-v2-filters__shop').val()
      $('.filter_card').data().shops = params.shopId
    } else {
      $('.filter_card').data().shops = 'all'
    }

    if($('#statistics-v2-filters__shop-two').length && $('#statistics-v2-filters__shop-two').val() != 'all') {
      params.shopIdTwo = $('#statistics-v2-filters__shop-two').val()
      $('.filter_card').data().shopsTwo = params.shopId
    } else {
      $('.filter_card').data().shopsTwo = 'all'
    }    

    if($('#statistics-v2-filters__input-ht').length) {
      params.ca = $('#statistics-v2-filters__input-ht').prop('checked') ? 'HT' : 'TTC'
      $('.ca_choice').text(params.ca)
      $('.filter_card').data().ca = params.ca
    }

    if($('#statistics-v2-filters__group-orders').length) {
      params.group_orders = $('#statistics-v2-filters__group-orders').val()
      $('.filter_card').data().grouporders = params.group_orders
    }

    if($('#statistics-v2-filters__employee').length && $('#statistics-v2-filters__employee').val() != 'all') {
      params.employeeId = $('#statistics-v2-filters__employee').val()
    }

    if($('#statistics-v2-filters__product-filter').length && $('#statistics-v2-filters__product-filter').val() != 'all') {
      params.productUniqueId = $('#statistics-v2-filters__product-filter').val()
    }

    if($('#statistics-v2-filters__contact-type').length && $('#statistics-v2-filters__contact-type').val() != 'all') {
      params.contact_type = $('#statistics-v2-filters__contact-type').val()

    }
    if($('.filter_card').length) {
      $('.filter_card').data().contactType = params.contact_type
    }

    if($('#statistics-v2-filters__day-filter').length && $('#statistics-v2-filters__day-filter').val() != 'all') {
      params.day_filter = $('#statistics-v2-filters__day-filter').val()
    }

    if($('#statistics-v2-display__group-by-day').length && $('#statistics-v2-display__group-by-day').prop('checked')) {
      params.groupByDay = 'true'
    }

    if($('#statistics-v2-display__separate-shops').length && $('#statistics-v2-display__separate-shops').prop('checked')) {
      params.separateShops = 'true'
    }    


    if($('#statistics-v2-filters__tags-filter').length && $('#statistics-v2-filters__tags-filter').val().length > 0) {
      params.selected_tags = $('#statistics-v2-filters__tags-filter').val().join('_')
      $('.filter_card').data().tags = $('#statistics-v2-filters__tags-filter').val()
    }

    if($('#statistics-v2-filters__source-order-types').length) {
      params.sourceOrderType = $('#statistics-v2-filters__source-order-types').val().join('_')
      $('.filter_card').data().sourceOrderType = $('#statistics-v2-filters__source-order-types').val()

    }
    return params    
  }

  setUrls(params) {
    let query = new URLSearchParams(params).toString()
    $.each($('.menu_statistics_v2_link'), (i, a) => {
      $(a).attr('href', `${$(a).attr('href').split('?')[0]}?${query}`)
    })  
  }

  filter() {
    let params = this.prepareParams()

    this.setUrls(params)
    this.refresh(params)
  }
}
