import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="back--discount-codes-form"
export default class extends Controller {
  connect() {
    $(this.element).on('click', '.discount_code_discount_type_button', () => {
      setTimeout(() => {
        $('#discount_code_amount').valid()
      }, 100)
    })
  }
}
